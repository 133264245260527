import Home_main from "./asset/ko/Home_main.png";
// -----ko------
import ko_Rollingpaper from "./asset/ko/Rollingpaeper_Sample.png";
import ko_Rollingpaper2 from "./asset/ko/Rollingpaper2_Sample.png";
import ko_Alcatine from "./asset/ko/Alcatine_Sample.png";
import ko_Angel from "./asset/ko/Angel_Sample.png";
import ko_Mood from "./asset/ko/Mood_Sample.png";
import ko_Insomnia from "./asset/ko/Insomnia_Sample.png";
import ko_Nojam from "./asset/ko/Nojam_Sample.png";
import ko_Playlist from "./asset/ko/Playlist_Sample.png";
import ko_Reallove from "./asset/ko/Reallove_Sample.png";
import ko_School from "./asset/ko/School_Sample.png";
import ko_Zombie from "./asset/ko/Zombie_Sample.png";
import ko_Keyword from "./asset/ko/Keyword_Sample.png";
import ko_Flirting from "./asset/ko/Flirting_Sample.png";
import ko_Song2000 from "./asset/ko/Song2000_Sample.png";
import ko_Animal from "./asset/ko/Animal_sample.png";
import ko_Pick2024 from "./asset/ko/Pick2024_sample.png";
import ko_Pick20242 from "./asset/ko/Pick2024_sample2.png";
import ko_RP2024 from "./asset/ko/RP2024_Sample.png";
import ko_Blackcat from "./asset/ko/Blackcat_Sample.png";
import ko_RP2024love from "./asset/ko/RP2024love_Sample.png";
import ko_Lovebalance from "./asset/ko/Lovebalance_Sample.png";
import ko_CatLovesMe from "./asset/ko/CatlovesMe_Sample.png";
import ko_Wordtype from "./asset/ko/Wordtype_Sample.png";
import ko_Bigfive from "./asset/ko/Bigfive_Sample.png";
import ko_Olympos from "./asset/ko/Olympos_sample.png";
import ko_Lovethink from "./asset/ko/Lovethink_sample.png";
import ko_TheHouse from "./asset/ko/TheHouse_Sample.png";
import ko_Stability from "./asset/ko/Stability_Sample.png";
import ko_Aptitude from "./asset/ko/Aptitude_Sample.png";
import ko_Lovemood from "./asset/ko/Lovemood_Sample.png";
import ko_Sisters from "./asset/ko/Sisters_sample.png";
import ko_Krcolor from "./asset/ko/Krcolor_Sample.png";
import ko_Inmyhead from "./asset/ko/Inmyhead_sample.png";
import ko_Testping from "./asset/ko/Testping_sample.png";
import ko_Animal2 from "./asset/ko/Animal2_sample.png";
import ko_core from "./asset/ko/Core_sample.png";
import ko_animal2025 from "./asset/ko/2025Animal_sample.png";

// -----kakao------

import kakao_angel from "./asset/ko/kakao/angel_kakao.png";
import kakao_alcatine from "./asset/ko/kakao/alcatine_kakao.png";
import kakao_playlist from "./asset/ko/kakao/playlist_kakao.png";
import kakao_reallove from "./asset/ko/kakao/reallove_kakao.png";
import kakao_keyword from "./asset/ko/kakao/keyword_kakao.png";
import kakao_flirting from "./asset/ko/kakao/flirting_kakao.png";
import kakao_song2000 from "./asset/ko/kakao/song2000_kakao.png";

// -----en------
import en_Rollingpaper from "./asset/en/en_Rollingpaper_Sample.png";
import en_Rollingpaper2 from "./asset/en/en_Rollingpaper2_Sample.png";
import en_Alcatine from "./asset/en/en_Alcatine_Sample.png";
import en_Angel from "./asset/en/en_Angel_Sample.png";
import en_Mood from "./asset/en/en_Mood_Sample.png";
import en_Insomnia from "./asset/en/en_Insomnia_Sample.png";
import en_Nojam from "./asset/en/en_Nojam_Sample.png";
import en_Playlist from "./asset/en/en_Playlist_Sample.png";
import en_Reallove from "./asset/en/en_Reallove_Sample.png";
import en_School from "./asset/en/en_School_Sample.png";
import en_Zombie from "./asset/en/en_Zombie_Sample.png";
import en_Keyword from "./asset/en/en_Keyword_Sample.png";
import en_Animal1 from "./asset/en/en_Animal_Sample.png";
import en_Animal2 from "./asset/en/en_Animal_Sample2.png";
import en_Blackcat from "./asset/en/en_Blackcat_Sample.png";
import en_CatLovesMe from "./asset/en/en_CatlovesMe.Sample.png";
import en_Wordtype from "./asset/en/en_Wordtype_Sample.png";
import en_Bigfive from "./asset/en/en_Bigfive_Sample.png";
import en_Olympos from "./asset/en/en_Olympos_Sample.png";
import en_TheHouse from "./asset/en/en_TheHouse_Sample.png";

// -----jp------
import jp_Rollingpaper from "./asset/jp/Rollingpaperjp_Sample.png";
import jp_Mood from "./asset/jp/Yourmoodjp_Sample.png";
import jp_Zombie from "./asset/jp/Zombiejp_Sample.png";
import jp_Rollingpaper2 from "./asset/jp/Rollingpaper2jp_Sample.png";
import jp_Reallove from "./asset/jp/Reallovejp_Sample.png";
import jp_Keyword from "./asset/jp/KeywordMbtijp_Sample.png";
import jp_Animal from "./asset/jp/animaltarotjp_Sample.png";
import jp_CatLovesMe from "./asset/jp/CatlovesMejp_Sample.png";

export const images = [
  {
    id: 72,
    lang: "ko",
    src: ko_animal2025,
    alt: "ko_animal2025",
    description: "2025동물타로_한국어",
  },
  {
    id: 71,
    lang: "ko",
    src: ko_core,
    alt: "ko_core",
    description: "코어감정_한국어",
  },
  {
    id: 70,
    lang: "ko",
    src: ko_Animal2,
    alt: "ko_Animal2",
    description: "동물타로2_한국어",
  },
  {
    id: 69,
    lang: "ko",
    src: ko_Testping,
    alt: "ko_Testping",
    description: "ㅇㅇ핑_한국어",
  },
  {
    id: 68,
    lang: "ko",
    src: ko_Inmyhead,
    alt: "ko_Inmyhead",
    description: "요즘내머릿속_한국어",
  },
  {
    id: 67,
    lang: "ko",
    src: ko_Krcolor,
    alt: "ko_Krcolor",
    description: "전통색_한국어",
  },
  {
    id: 66,
    lang: "ko",
    src: ko_Sisters,
    alt: "ko_Sisters",
    description: "자매유형_한국어",
  },
  {
    id: 65,
    lang: "ko",
    src: Home_main,
    alt: "Home_main",
    description: "테이크파이브_메인",
  },
  {
    id: 64,
    lang: "ko",
    src: ko_Lovemood,
    alt: "ko_Lovemood",
    description: "연애분위기_한국어",
  },
  {
    id: 63,
    lang: "ko",
    src: ko_Aptitude,
    alt: "ko_Aptitude",
    description: "직업적성_한국어",
  },
  {
    id: 62,
    lang: "ko",
    src: ko_Stability,
    alt: "ko_Stability",
    description: "연애안정성_한국어",
  },
  {
    id: 61,
    lang: "en",
    src: en_TheHouse,
    alt: "en_TheHouse",
    description: "마음의집_영어",
  },
  {
    id: 61,
    lang: "ko",
    src: ko_TheHouse,
    alt: "ko_TheHouse",
    description: "마음의집_한국어",
  },
  {
    id: 60,
    lang: "en",
    src: en_Olympos,
    alt: "en_Olympos",
    description: "올림포스_영어",
  },
  {
    id: 59,
    lang: "ko",
    src: ko_Lovethink,
    alt: "ko_Lovethink",
    description: "연애사고_한국어",
  },
  {
    id: 58,
    lang: "en",
    src: en_Bigfive,
    alt: "en_Bigfive",
    description: "빅파이브_영어",
  },
  {
    id: 57,
    lang: "ko",
    src: ko_Olympos,
    alt: "ko_Olympos",
    description: "올림포스mbti_한국어",
  },
  {
    id: 56,
    lang: "ko",
    src: ko_Bigfive,
    alt: "ko_Bigfive",
    description: "빅파이브_한국어",
  },
  {
    id: 55,
    lang: "en",
    src: en_Wordtype,
    alt: "en_Wordtype",
    description: "단어성격유형검사_영어",
  },
  {
    id: 54,
    lang: "ko",
    src: ko_Wordtype,
    alt: "ko_Wordtype",
    description: "단어성격유형검사_한국어",
  },
  {
    id: 53,
    lang: "jp",
    src: jp_CatLovesMe,
    alt: "jp_CatLovesMe",
    description: "고양이애정도테스트_일본어",
  },
  {
    id: 52,
    lang: "en",
    src: en_CatLovesMe,
    alt: "en_CatLovesMe",
    description: "고양이애정도테스트_영어",
  },
  {
    id: 51,
    lang: "ko",
    src: ko_CatLovesMe,
    alt: "ko_CatLovesMe",
    description: "고양이애정도테스트_한국어",
  },
  {
    id: 50,
    lang: "ko",
    src: ko_Lovebalance,
    alt: "ko_Lovebalance",
    description: "연애밸런스게임_한국어",
  },
  {
    id: 49,
    lang: "jp",
    src: jp_Animal,
    alt: "jp_Animal",
    description: "동물타로_일본어",
  },
  {
    id: 48,
    lang: "jp",
    src: jp_Keyword,
    alt: "jp_Keyword",
    description: "키워드_일본어",
  },
  {
    id: 47,
    lang: "jp",
    src: jp_Reallove,
    alt: "jp_Reallove",
    description: "찐사랑_일본어",
  },
  {
    id: 46,
    lang: "jp",
    src: jp_Rollingpaper2,
    alt: "jp_Rollingpaper2",
    description: "롤링페이퍼2_일본어",
  },
  {
    id: 45,
    lang: "jp",
    src: jp_Mood,
    alt: "jp_Mood",
    description: "당신의분위기_일본어",
  },
  {
    id: 44,
    lang: "jp",
    src: jp_Zombie,
    alt: "jp_Zombie",
    description: "좀비_일본어",
  },
  {
    id: 43,
    lang: "ko",
    src: ko_RP2024love,
    alt: "ko_RP2024love",
    description: "롤링페이퍼2024연애편_한국",
  },
  {
    id: 42,
    lang: "en",
    src: en_Blackcat,
    alt: "en_Blackcat",
    description: "검은고양이_영어",
  },
  {
    id: 41,
    lang: "ko",
    src: ko_Blackcat,
    alt: "ko_Blackcat",
    description: "검은고양이_한국",
  },
  {
    id: 40,
    lang: "jp",
    src: jp_Rollingpaper,
    alt: "jp_Rollingpaper",
    description: "롤링페이퍼_일본어",
  },
  {
    id: 39,
    lang: "ko",
    src: ko_RP2024,
    alt: "ko_RP2024",
    description: "롤링페이퍼2024_한국",
  },
  {
    id: 38,
    lang: "en",
    src: en_Animal2,
    alt: "en_Animal2",
    description: "동물타로_영어",
  },
  {
    id: 37,
    lang: "en",
    src: en_Animal1,
    alt: "en_Animal1",
    description: "동물타로_영어",
  },
  {
    id: 36,
    lang: "ko",
    src: ko_Pick20242,
    alt: "ko_Pick20242",
    description: "운세뽑기2_한국",
  },
  {
    id: 35,
    lang: "ko",
    src: ko_Pick2024,
    alt: "ko_Pick2024",
    description: "운세뽑기_한국",
  },
  {
    id: 34,
    lang: "ko",
    src: ko_Animal,
    alt: "ko_Animal",
    description: "동물타로_한국",
  },
  {
    id: 33,
    lang: "ko",
    src: kakao_song2000,
    alt: "kakao_song2000",
    description: "2000년대노래_카카오",
  },
  {
    id: 32,
    lang: "ko",
    src: kakao_flirting,
    alt: "kakao_flirting",
    description: "플러팅_카카오",
  },
  {
    id: 31,
    lang: "ko",
    src: kakao_keyword,
    alt: "kakao_keyword",
    description: "키워드_카카오",
  },
  {
    id: 30,
    lang: "ko",
    src: kakao_reallove,
    alt: "kakao_reallove",
    description: "찐사랑_카카오",
  },
  {
    id: 29,
    lang: "ko",
    src: kakao_playlist,
    alt: "kakao_playlist",
    description: "플레이리스트_카카오",
  },
  {
    id: 28,
    lang: "ko",
    src: kakao_alcatine,
    alt: "kakao_alcatine",
    description: "알카틴_카카오",
  },
  {
    id: 27,
    lang: "ko",
    src: kakao_angel,
    alt: "kakao_angel",
    description: "수호천사_카카오",
  },
  {
    id: 26,
    lang: "ko",
    src: ko_Song2000,
    alt: "ko_Song2000",
    description: "2000년대노래_한국",
  },
  {
    id: 25,
    lang: "ko",
    src: ko_Flirting,
    alt: "ko_Flirting",
    description: "플러팅_한국",
  },
  {
    id: 24,
    lang: "en",
    src: en_Keyword,
    alt: "en_Keyword",
    description: "키워드_영어",
  },
  {
    id: 23,
    lang: "ko",
    src: ko_Keyword,
    alt: "ko_Keyword",
    description: "키워드_한국",
  },
  {
    id: 22,
    lang: "en",
    src: en_Zombie,
    alt: "en_Zombie",
    description: "좀비_영어",
  },
  {
    id: 21,
    lang: "en",
    src: en_School,
    alt: "en_School",
    description: "초딩_영어",
  },
  {
    id: 20,
    lang: "en",
    src: en_Reallove,
    alt: "en_Reallove",
    description: "찐사랑_영어",
  },
  {
    id: 19,
    lang: "en",
    src: en_Playlist,
    alt: "en_Playlist",
    description: "플레이리스트_영어",
  },
  {
    id: 18,
    lang: "en",
    src: en_Nojam,
    alt: "en_Nojam",
    description: "노잼_영어",
  },
  {
    id: 17,
    lang: "en",
    src: en_Insomnia,
    alt: "en_Insomnia",
    description: "불면증_영어",
  },
  {
    id: 16,
    lang: "en",
    src: en_Mood,
    alt: "en_Mood",
    description: "분위기_영어",
  },
  {
    id: 15,
    lang: "en",
    src: en_Angel,
    alt: "en_Angel",
    description: "수호천사_영어",
  },
  {
    id: 14,
    lang: "en",
    src: en_Alcatine,
    alt: "en_Alcatine",
    description: "알카틴_영어",
  },
  {
    id: 13,
    lang: "en",
    src: en_Rollingpaper2,
    alt: "en_Rollingpaper2",
    description: "롤링페이퍼2_영어",
  },
  {
    id: 12,
    lang: "en",
    src: en_Rollingpaper,
    alt: "en_Rollingpaper",
    description: "롤링페이퍼_영어",
  },
  {
    id: 11,
    lang: "ko",
    src: ko_Zombie,
    alt: "ko_Zombie",
    description: "좀비_한국",
  },
  {
    id: 10,
    lang: "ko",
    src: ko_School,
    alt: "ko_School",
    description: "초딩시절_한국",
  },
  {
    id: 9,
    lang: "ko",
    src: ko_Reallove,
    alt: "ko_Reallove",
    description: "찐사랑_한국",
  },
  {
    id: 8,
    lang: "ko",
    src: ko_Playlist,
    alt: "ko_Playlist",
    description: "플레이리스트_한국",
  },
  {
    id: 7,
    lang: "ko",
    src: ko_Nojam,
    alt: "ko_Nojam",
    description: "노잼력_한국",
  },
  {
    id: 6,
    lang: "ko",
    src: ko_Insomnia,
    alt: "ko_Insomnia",
    description: "통모짜불면증_한국",
  },
  {
    id: 5,
    lang: "ko",
    src: ko_Mood,
    alt: "ko_Mood",
    description: "당신의분위기_한국",
  },
  {
    id: 4,
    lang: "ko",
    src: ko_Angel,
    alt: "ko_Angel",
    description: "수호천사_한국",
  },
  {
    id: 3,
    lang: "ko",
    src: ko_Alcatine,
    alt: "ko_Alcatine",
    description: "알카틴_한국",
  },
  {
    id: 2,
    lang: "ko",
    src: ko_Rollingpaper2,
    alt: "ko_Rollingpaper2",
    description: "롤링페이퍼2_한국",
  },
  {
    id: 1,
    lang: "ko",
    src: ko_Rollingpaper,
    alt: "ko_Rollingpaper",
    description: "롤링페이퍼_한국",
  },
];

export default images;
